export class SpinnerHelper {

    constructor() {

        this.spinner = document.querySelector('.global-spinner');
    }

    show() {

        if (!this.spinner)
            return;

        this.spinner.style = 'display: block;';
    }

    
    hide() {

        if (!this.spinner)
            return;

        this.spinner.style = '';
    }
}