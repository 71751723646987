export class Interviews {
    constructor() {
        this.select_interview_personal_scenario_marital_status = document.getElementById('select_interview_personal_scenario_marital_status');
        this.input_interview_personal_scenario_marital_status_time = document.getElementById('input_interview_personal_scenario_marital_status_time');

        this.radio_interview_personal_scenario_sons = document.getElementsByName('radio_interview_personal_scenario_sons');
        this.interview_personal_scenario_sons = document.getElementsByClassName('interview_personal_scenario_sons');

        this.select_interview_personal_scenario_sons = document.getElementById('select_interview_personal_scenario_sons');

        this.interview_personal_scenario_sons_age = document.getElementById('interview_personal_scenario_sons_age');

        this.radio_interview_health_scenario_health_treatment = document.getElementsByName('radio_interview_health_scenario_health_treatment');
        this.input_interview_health_scenario_health_treatment_other = document.getElementById('input_interview_health_scenario_health_treatment_other');

        this.radio_interview_health_scenario_health_hospitalization = document.getElementsByName('radio_interview_health_scenario_health_hospitalization');
        this.input_interview_health_scenario_health_hospitalization_other = document.getElementById('input_interview_health_scenario_health_hospitalization_other');

        this.radio_interview_health_scenario_health_fracture = document.getElementsByName('radio_interview_health_scenario_health_fracture');
        this.input_interview_health_scenario_health_fracture_other = document.getElementById('input_interview_health_scenario_health_fracture_other');

        this.radio_interview_health_scenario_health_medicine = document.getElementsByName('radio_interview_health_scenario_health_medicine');
        this.input_interview_health_scenario_health_medicine_other = document.getElementById('input_interview_health_scenario_health_medicine_other');

        this.radio_interview_health_scenario_health_surgery = document.getElementsByName('radio_interview_health_scenario_health_surgery');
        this.input_interview_health_scenario_health_surgery_other = document.getElementById('input_interview_health_scenario_health_surgery_other');

        this.radio_interview_health_scenario_health_physical_activity = document.getElementsByName('radio_interview_health_scenario_health_physical_activity');
        this.input_interview_health_scenario_health_physical_activity_other = document.getElementById('input_interview_health_scenario_health_physical_activity_other');

        this.radio_interview_health_scenario_sleeping_medication = document.getElementsByName('radio_interview_health_scenario_sleeping_medication');
        this.input_interview_health_scenario_sleeping_medication_when = document.getElementById('input_interview_health_scenario_sleeping_medication_when');
        this.input_interview_health_scenario_sleeping_medication_what = document.getElementById('input_interview_health_scenario_sleeping_medication_what');

        this.radio_interview_health_scenario_alcohol = document.getElementsByName('radio_interview_health_scenario_alcohol');
        this.input_interview_health_scenario_alcohol_which = document.getElementById('input_interview_health_scenario_alcohol_which');
        this.input_interview_health_scenario_alcohol_frequency = document.getElementById('input_interview_health_scenario_alcohol_frequency');

        this.radio_interview_health_scenario_smoke = document.getElementsByName('radio_interview_health_scenario_smoke');
        this.input_interview_health_scenario_smoke_which = document.getElementById('input_interview_health_scenario_smoke_which');

        this.radio_interview_health_scenario_psychoactive_substance = document.getElementsByName('radio_interview_health_scenario_psychoactive_substance');
        this.input_interview_health_scenario_psychoactive_substance_which = document.getElementById('input_interview_health_scenario_psychoactive_substance_which');
        this.input_interview_health_scenario_psychoactive_substance_frequency = document.getElementById('input_interview_health_scenario_psychoactive_substance_frequency');

        this.radio_interview_hard_situation = document.getElementsByName('radio_interview_hard_situation');
        this.input_interview_hard_situation_which = document.getElementById('input_interview_hard_situation_which');

        this.radio_interview_health_scenario_traffic_accident = document.getElementsByName('radio_interview_health_scenario_traffic_accident');
        this.input_interview_health_scenario_traffic_accident = document.getElementById('input_interview_health_scenario_traffic_accident');

        this.checkbox_unforeseen_others = document.getElementById('checkbox_unforeseen_others');
        this.input_unforeseen_others = document.getElementById('input_unforeseen_others');

        this.checkbox_changing_others = document.getElementById('checkbox_changing_others');
        this.input_changing_others = document.getElementById('input_changing_others');

        this.checkbox_respect_others = document.getElementById('checkbox_respect_others');
        this.input_respect_others = document.getElementById('input_respect_others');

        this.radio_interview_personal_scenario_veicule_type_other = document.getElementById('radio_interview_personal_scenario_veicule_type_other');
        this.input_veicule_type_other = document.getElementById('input_veicule_type_other');


        this.init();
    }

    init() {
        let _self = this;

        let url_atual = window.location.href;

        if (url_atual.search('interviews') != -1) {

            if (this.radio_interview_personal_scenario_veicule_type_other) {
                this.radio_interview_personal_scenario_veicule_type_other.addEventListener('change', function () {
                    if (this.checked)
                        _self.input_veicule_type_other.disabled = false;
                    else {
                        _self.input_veicule_type_other.value = '';
                        _self.input_veicule_type_other.disabled = true;
                    }
                });
            }

            this.checkbox_unforeseen_others.addEventListener('change', function () {
                if (this.checked)
                    _self.input_unforeseen_others.disabled = false;
                else {
                    _self.input_unforeseen_others.value = '';
                    _self.input_unforeseen_others.disabled = true;
                }
            });

            this.checkbox_respect_others.addEventListener('change', function () {
                if (this.checked)
                    _self.input_respect_others.disabled = false;
                else {
                    _self.input_respect_others.value = '';
                    _self.input_respect_others.disabled = true;
                }
            });
            if(this.checkbox_changing_others) {
                this.checkbox_changing_others.addEventListener('change', function () {
                    if (this.checked)
                        _self.input_changing_others.disabled = false;
                    else {
                        _self.input_changing_others.value = '';
                        _self.input_changing_others.disabled = true;
                    }
                });
            }

            this.initDisplayFields('interview_personal_scenario_sons');

            this.initDisplayFields('interview_personal_scenario_sons_age');

            this.select_interview_personal_scenario_marital_status.addEventListener('change', function () {
                _self.fieldEnablementBySelectController('select_interview_personal_scenario_marital_status', 'solteiro', 'input_interview_personal_scenario_marital_status_time', 'disabled');
            });

            this.radio_interview_personal_scenario_sons.forEach((element, index) => {

                if (element.value == 'Sim' && element.checked == true) {
                    _self.fieldDisplayByNameController('interview_personal_scenario_sons', element);
                    _self.interviewFieldsChildDisplayBySelectController('select_interview_personal_scenario_sons', 'interview_personal_scenario_sons_age');
                }

                this.radio_interview_personal_scenario_sons[index].addEventListener('change', function () {
                    _self.initDisplayFields('interview_personal_scenario_sons_age');
                    _self.fieldDisplayByNameController('interview_personal_scenario_sons', element);
                });
            });

            this.select_interview_personal_scenario_sons.addEventListener('change', function () {
                _self.interviewFieldsChildDisplayBySelectController('select_interview_personal_scenario_sons', 'interview_personal_scenario_sons_age');
            });

            this.radio_interview_health_scenario_health_treatment.forEach((element, index) => {
                this.radio_interview_health_scenario_health_treatment[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_health_treatment_other'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_health_hospitalization.forEach((element, index) => {
                this.radio_interview_health_scenario_health_hospitalization[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_health_hospitalization_other'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_health_fracture.forEach((element, index) => {
                this.radio_interview_health_scenario_health_fracture[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_health_fracture_other'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_health_medicine.forEach((element, index) => {
                this.radio_interview_health_scenario_health_medicine[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_health_medicine_other'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_health_surgery.forEach((element, index) => {
                this.radio_interview_health_scenario_health_surgery[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_health_surgery_other'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_health_physical_activity.forEach((element, index) => {
                this.radio_interview_health_scenario_health_physical_activity[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_health_physical_activity_other'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_sleeping_medication.forEach((element, index) => {
                this.radio_interview_health_scenario_sleeping_medication[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_sleeping_medication_when', 'input_interview_health_scenario_sleeping_medication_what'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_alcohol.forEach((element, index) => {
                this.radio_interview_health_scenario_alcohol[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_alcohol_which', 'input_interview_health_scenario_alcohol_frequency'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_smoke.forEach((element, index) => {
                this.radio_interview_health_scenario_smoke[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_smoke_which'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_psychoactive_substance.forEach((element, index) => {
                this.radio_interview_health_scenario_psychoactive_substance[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_psychoactive_substance_which', 'input_interview_health_scenario_psychoactive_substance_frequency'], 'disabled', element);
                });
            });

            this.radio_interview_hard_situation.forEach((element, index) => {
                this.radio_interview_hard_situation[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_hard_situation_which'], 'disabled', element);
                });
            });

            this.radio_interview_health_scenario_traffic_accident.forEach((element, index) => {
                this.radio_interview_health_scenario_traffic_accident[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameYesOrNoController('Sim', ['input_interview_health_scenario_traffic_accident'], 'disabled', element);
                });
            });
        }
    }

    /*
        @description: Função responsável por controlar (por name e radio) o estado dos inputs Yes/No (ex.: habilitar/desabilitar)
        @param target_condition: Condição do objeto alvo
        @param targets: Objetos alvo
        @param target_condition: Condição do objeto alvo
        @param element: Elemento iterado por name
    */
    fieldEnablementByNameYesOrNoController(controller_condition, targets, target_condition, element) {
        Array.prototype.forEach.call(targets, function(_target) {
            if(element.value == controller_condition) {
                $('#' + _target).prop(target_condition, false);
            } else {
                $('#' + _target).prop(target_condition, true);
            }
        });
    }

    /*
        @description: Função responsável por controlar (por select) o estado dos inputs (ex.: habilitar/desabilitar)
        @param controller: Controlador
        @param controller_condition: Condição do controlador
        @param target: Objeto alvo
        @param target_condition: Condição do objeto alvo
    */
    fieldEnablementBySelectController(controller, controller_condition, target, target_condition) {
        if($('#' + controller)[0].value == controller_condition) {
            $('#' + target).prop(target_condition, true);
        } else {
            $('#' + target).prop(target_condition, false);
        }
    }

    /*
        @description: Função responsável por controlar (por name e radio) o estado dos inputs (ex.: exibir/esconder)
        @param target: Objeto alvo
        @param element: Elemento iterado por name
    */
    fieldDisplayByNameController(target, element) {
        let targets = $('.' + target);

        Array.prototype.forEach.call(targets, function(_target) {
            if (element.value == 'Sim') {
                _target.style.display = 'block';
            } else {
                _target.children[0].value = '';
                _target.style.display = 'none';
            }
        });
    }

    /*
        @description: Função responsável por controlar o estado inicial da exibição dos inputs
        @param target_name: Name do campo
    */
    initDisplayFields(target_name) {
        let targets = document.getElementsByClassName(target_name);

        Array.prototype.forEach.call(targets, function(_target) {
            _target.style.display = 'none';
        });
    }

    /*
        @description: Função do formulário de Entrevista responsável por controlar (por select) a quantidade de inputs de Idade dos Filhos a serem exibidos
        @param controller: ID do controlador
        @param target: Name do objeto alvo
    */
    interviewFieldsChildDisplayBySelectController(controller_id, target_name) {
        let targets = document.getElementsByClassName(target_name);

        Array.prototype.forEach.call(targets, function(_target) {
            _target.style.display = 'none';
        });

        let controller_value = parseInt($('#' + controller_id)[0].value);

        Array.prototype.forEach.call(targets, function(_target) {
            let _id = parseInt(_target.children[0].id.split('_')[6]);

            if (_id <= controller_value) {
                _target.style.display = 'block';
            }
        });
    }
}
