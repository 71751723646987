import { TempusDominus } from '@eonasdan/tempus-dominus'
import '@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css'

export class DatetimeHelper 
{
    #config = {
        display: {
            icons: {
                type: 'icons',
                time: 'fa-solid fa-clock',
                date: 'fa-solid fa-calendar',
                up: 'fa-solid fa-arrow-up',
                down: 'fa-solid fa-arrow-down',
                previous: 'fa-solid fa-chevron-left',
                next: 'fa-solid fa-chevron-right',
                today: 'fa-solid fa-calendar-check',
                clear: 'fa-solid fa-trash',
                close: 'fa-solid fa-xmark'
            },
            buttons: {
                close: true
            },
        },
        localization: {
            locale: 'pt-BR',
            format: 'dd/MM/yyyy HH:mm',
            dayViewHeaderFormat: { 
                month: 'long', 
                year: 'numeric' 
            },
        }
    }

    #oneDayAgo = new Date(
        ( new Date() ).getFullYear(), 
        ( new Date() ).getMonth(), 
        (( new Date() ).getDate() -1)
    )
    
    #datetimeCssClass = 'td-datetime'
    #dateCssClass = 'td-date'

    #datetimeSchedulingCssClass = 'td-datetime-scheduling'
    #dateSchedulingCssClass = 'td-date-scheduling'
    
    #birthdayCssClass = 'td-birthday'

    #datetimeCalendarFormElements
    #dateFormElements

    #datetimeSchedulingCalendarFormElements
    #dateSchedulingFormElements
    
    #birthdayFormElements

    constructor() {
        this.#datetimeCalendarFormElements = document.getElementsByClassName(this.#datetimeCssClass)
        this.#dateFormElements = document.getElementsByClassName(this.#dateCssClass)

        this.#datetimeSchedulingCalendarFormElements = document.getElementsByClassName(this.#datetimeSchedulingCssClass)
        this.#dateSchedulingFormElements = document.getElementsByClassName(this.#dateSchedulingCssClass)
        
        this.#birthdayFormElements = document.getElementsByClassName(this.#birthdayCssClass)

        this.init()
    }

    init() {
        this.initDatetimes()    
        this.initDates()    

        this.initDatetimesScheduling()    
        this.initDatesScheduling()    

        this.initBirthdays()    
    }

    initDatetimes() {
        this.#datetimeCalendarFormElements.forEach(element => {
            new TempusDominus(element, {
                ...this.#config,
                stepping: 15,
                restrictions: {
                    enabledHours: [8,9,10,11,12,13,14,15,16,17]
                }
            })

            this.preventPlaceholderOverride(element)
        });
    }

    initDates() {
        this.#dateFormElements.forEach(element => {
            var config = this.#config
            config.localization.format = 'dd/MM/yyyy' 

            new TempusDominus(element, {
                ...config,
                viewDate: new Date(),
                display: {
                    components: {
                        clock: false
                    }
                }
            })

            this.preventPlaceholderOverride(element)
        });
    }

    initDatetimesScheduling() {
        this.#datetimeSchedulingCalendarFormElements.forEach(element => {
            new TempusDominus(element, {
                ...this.#config,
                stepping: 15,
                restrictions: {
                    minDate: new Date(),
                    enabledHours: [8,9,10,11,12,13,14,15,16,17]
                }
            })

            this.preventPlaceholderOverride(element)
        });
    }

    initDatesScheduling() {
        this.#dateSchedulingFormElements.forEach(element => {
            var config = this.#config
            config.localization.format = 'dd/MM/yyyy' 

            new TempusDominus(element, {
                ...config,
                viewDate: new Date(),
                display: {
                    components: {
                        clock: false
                    }
                },
                restrictions: {
                    minDate: new Date()
                }
            })

            this.preventPlaceholderOverride(element)
        });
    }

    initBirthdays() {
        this.#birthdayFormElements.forEach(element => {
            var config = this.#config
            config.localization.format = 'dd/MM/yyyy' 

            new TempusDominus(element, {
                ...config,
                restrictions: {
                    maxDate: this.#oneDayAgo
                },
                display: {
                    components: {
                        clock: false
                    }
                }
            })

            this.preventPlaceholderOverride(element)
        });
    }

    preventPlaceholderOverride(element) {
        element.addEventListener('change.td', function() {
            this.focus()
        })
    }
}