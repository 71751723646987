import { SpinnerHelper } from './SpinnerHelper';

export class ModalDialog
{
    #defaultTitle = 'Atenção'
    #defaultMessage = 'Tem certeza que deseja prosseguir com essa ação?'
    #cssSeletor = 'modal-app'
    #cssFormTrigger = 'form-modal-confirmation'

    #form
    #modal
    #btnTwo
    #modalInstance
    #elementFormTrigger
    #spinner

    constructor() {
        this.init()
    }

    init() {
        this.initSpinner()
        this.setTrigger()
        this.initModal()
        this.initModalTriggeredByForm()
    }

    initSpinner() {
        this.#spinner = new SpinnerHelper;
    }

    setTrigger() {
        this.#elementFormTrigger = document.getElementsByClassName(this.#cssFormTrigger)
    }

    initModal() {
        this.#modal = document.getElementById(this.#cssSeletor)
        this.#modalInstance = new mdb.Modal(this.#modal);
    }

    initModalTriggeredByForm() {
        this.#elementFormTrigger.forEach(element => {
            element.addEventListener('click', (event) => {
                this.#spinner.show();

                this.#form = element.parentNode

                let title = this.#defaultTitle
                let message = this.#defaultMessage

                if(element.getAttribute('data-modal-title')) {
                    title   = element.getAttribute('data-modal-title')
                }

                if(element.getAttribute('data-modal-body')) {
                    message = element.getAttribute('data-modal-body')
                }

                this.#modal.querySelector('.modal-title').innerHTML = title
                this.#modal.querySelector('.modal-body').innerHTML  = message

                this.#spinner.hide();
                this.#modalInstance.show();

                this.addEventToButtonTwoTriggeredByForm()
                // const myElement = document.querySelector('#myElement');
                // this.#modal.style.display = 'block';

            })
        })
    }

    addEventToButtonTwoTriggeredByForm() {
        this.#btnTwo = this.#modal.querySelector('#btn-two')

        this.#btnTwo.addEventListener('click', (event) => {
            this.#form.submit()
        })
    }
}