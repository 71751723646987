export class Notes {
    constructor() {
        this.checkbox_attitude_other_profile = document.getElementById('checkbox_attitude_other_profile');
        this.input_attitude_other_profile = document.getElementById('input_attitude_other_profile');

        this.radio_thought_profile = document.getElementsByName('radio_thought_profile');
        this.input_thought_other_profile = document.getElementById('input_thought_other_profile');

        this.radio_humor_profile = document.getElementsByName('radio_humor_profile');
        this.input_humor_other_profile = document.getElementById('input_humor_other_profile');

        this.radio_behavior_profile = document.getElementsByName('radio_behavior_profile');
        this.input_behavior_other_profile = document.getElementById('input_behavior_other_profile');

        this.radio_speech_profile = document.getElementsByName('radio_speech_profile');
        this.input_speech_other_profile = document.getElementById('input_speech_other_profile');

        this.radio_consciousness_profile = document.getElementsByName('radio_consciousness_profile');
        this.input_consciousness_other_profile = document.getElementById('input_consciousness_other_profile');

        this.radio_psicomotricidade_profile = document.getElementsByName('radio_psicomotricidade_profile');
        this.input_psicomotricidade_other_profile = document.getElementById('input_psicomotricidade_other_profile');

        this.radio_guidance_profile = document.getElementsByName('radio_guidance_profile');
        this.input_guidance_other_profile = document.getElementById('input_guidance_other_profile');

        this.init();
    }

    init() {
        let _self = this;

        let url_atual = window.location.href;

        if (url_atual.search('notes') != -1) {
            this.checkbox_attitude_other_profile.addEventListener('change', function () {

                if (this.checked)
                    _self.input_attitude_other_profile.disabled = false;
                else
                    _self.input_attitude_other_profile.disabled = true;
            });
            
            this.radio_thought_profile.forEach((element, index) => {
                this.radio_thought_profile[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameController('input_thought_other_profile', 'disabled', 'radio_thought_other_profile', element);
                });
            });
            
            this.radio_humor_profile.forEach((element, index) => {
                this.radio_humor_profile[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameController('input_humor_other_profile', 'disabled', 'radio_humor_other_profile', element);
                });
            });

            this.radio_behavior_profile.forEach((element, index) => {
                this.radio_behavior_profile[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameController('input_behavior_other_profile', 'disabled', 'radio_behavior_other_profile', element);
                });
            });

            this.radio_speech_profile.forEach((element, index) => {
                this.radio_speech_profile[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameController('input_speech_other_profile', 'disabled', 'radio_speech_other_profile', element);
                });
            });
            
            this.radio_consciousness_profile.forEach((element, index) => {
                this.radio_consciousness_profile[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameController('input_consciousness_other_profile', 'disabled', 'radio_consciousness_other_profile', element);
                });
            });
                
            this.radio_psicomotricidade_profile.forEach((element, index) => {
                this.radio_psicomotricidade_profile[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameController('input_psicomotricidade_other_profile', 'disabled', 'radio_psicomotricidade_other_profile', element);
                });
            });
            
            this.radio_guidance_profile.forEach((element, index) => {
                this.radio_guidance_profile[index].addEventListener('change', function () {
                    _self.fieldEnablementByNameController('input_guidance_other_profile', 'disabled', 'radio_guidance_other_profile', element);
                });
            });
        }
    }

    /* 
        @description: Função responsável por controlar (por id e checkbox) o estado dos inputs (ex.: habilitar/desabilitar)
        @param controller: Controlador
        @param controller_condition: Condição do controlador
        @param targets: Objetos alvo
        @param target_condition: Condição do objeto alvo
    */
    fieldEnablementController(controller, controller_condition, targets, target_condition) {
        Array.prototype.forEach.call(targets, function(_target) {
            if($('#' + controller).is(':' + controller_condition)) {
                $('#' + _target).prop(target_condition, true);
            } else {
                $('#' + _target).prop(target_condition, false);
            }
        });
    }
    
    /* 
        @description: Função responsável por controlar (por name e radio) o estado dos inputs (ex.: habilitar/desabilitar)
        @param target: Objeto alvo
        @param target_condition: Condição do objeto alvo
        @param controller_id: Objeto alvo por id
        @param element: Elemento iterado por name
    */
    fieldEnablementByNameController(target, target_condition, controller_id, element) {
        if (element.id == controller_id) {
            $('#' + target).prop(target_condition, false);
        } else {
            $('#' + target).prop(target_condition, true);
        }
    }
}