export class ImageHelper {

    constructor() {

        this.initPreview('.image-upload', '#image-preview');
    }

    initPreview(selector, showWhere) {
        
        this.inputFile = document.querySelector(selector);
        this.showWhere = document.querySelector(showWhere);

        if (!this.inputFile || !this.showWhere)
            return;

        this.inputFile.onchange = evt => {
            console.log(this.inputFile.file)
            const [file] = this.inputFile.files
            if (file) {
                this.showWhere.src = URL.createObjectURL(file)
            }
        }
    }
}