export class PasswordHelper {

    constructor() {
        this.myInput = document.getElementById("password");
        this.letter = document.getElementById("letter");
        this.capital = document.getElementById("capital");
        this.number = document.getElementById("number");
        this.length = document.getElementById("length");
        this.symbol = document.getElementById("symbols");

        this.initValidate();
    }

    initValidate() {

        if (!this.myInput || !this.letter || !this.capital || !this.number || !this.length || !this.symbol)
            return;
            
        let self = this;

        // When the user starts to type something inside the password field
        this.myInput.onkeyup = function() {
            // Validate lowercase letters
            let lowerCaseLetters = /[a-z]/g;
            if(self.myInput.value.match(lowerCaseLetters)) {  
                self.letter.classList.remove("invalid");
                self.letter.classList.add("valid");
            } else {
                self.letter.classList.remove("valid");
                self.letter.classList.add("invalid");
            }

            // Validate symbol
            let specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
            if(self.myInput.value.match(specialChars)) {  
                self.symbol.classList.remove("invalid");
                self.symbol.classList.add("valid");
            } else {
                self.symbol.classList.remove("valid");
                self.symbol.classList.add("invalid");
            }
            
            // Validate capital letters
            let upperCaseLetters = /[A-Z]/g;
            if(self.myInput.value.match(upperCaseLetters)) {  
                self.capital.classList.remove("invalid");
                self.capital.classList.add("valid");
            } else {
                self.capital.classList.remove("valid");
                self.capital.classList.add("invalid");
            }
            
            // Validate numbers
            let numbers = /[0-9]/g;
            if(self.myInput.value.match(numbers)) {  
                self.number.classList.remove("invalid");
                self.number.classList.add("valid");
            } else {
                self.number.classList.remove("valid");
                self.number.classList.add("invalid");
            }
            
            // Validate length
            if(self.myInput.value.length >= 8) {
                self.length.classList.remove("invalid");
                self.length.classList.add("valid");
            } else {
                self.length.classList.remove("valid");
                self.length.classList.add("invalid");
            }
        }
    }
}