export class AnalysisClosing {
    constructor() {
        this.radio_analysis_closing_closure = document.getElementsByName('radio_analysis_closing_closure');
        this.select_analysis_closing_closure_years = document.getElementById('select_analysis_closing_closure_years');
        this.select_analysis_closing_closure_months = document.getElementById('select_analysis_closing_closure_months');
        this.select_analysis_closing_closure_days = document.getElementById('select_analysis_closing_closure_days');

        this.radio_analysis_closing_forwarding_other = document.getElementById('radio_analysis_closing_forwarding_other');
        this.input_analysis_closing_forwarding_other = document.getElementById('input_analysis_closing_forwarding_other');

        if (this.radio_analysis_closing_forwarding_other && this.input_analysis_closing_forwarding_other) 
            this.init();         
        
            
           
    }

    init() {
        let _self = this;

        let url_atual = window.location.href;

        this.radio_analysis_closing_forwarding_other.addEventListener('change', function () {
           
            if (this.checked) {
               
                _self.input_analysis_closing_forwarding_other.disabled = false;
            }  else {
                _self.input_analysis_closing_forwarding_other.value = '';
                _self.input_analysis_closing_forwarding_other.disabled = true;
            }
        });

        if (this.radio_analysis_closing_closure.length > 0) {
            this.radio_analysis_closing_closure.forEach((element, index) => {
                this.radio_analysis_closing_closure[index].addEventListener('change', function () {
                    _self.analysisClosingClosureFieldEnablementYearsAndMonthsOrDaysController('disabled', element);
                });
            });
        }
    }

    /*
        @description: Função do formulário de Análise/Fechamento responsável por controlar (por radio) o estado dos inputs Anos/Mês/Dias (ex.: habilitar/desabilitar)
        @param target_condition: Condição do objeto alvo
        @param element: Elemento iterado por name
    */
    analysisClosingClosureFieldEnablementYearsAndMonthsOrDaysController(target_condition, element) {
        if ($('#' + element.id)[0].value == "Apto com diminuição de tempo") {
            $('#select_analysis_closing_closure_years').prop(target_condition, false);
            $('#select_analysis_closing_closure_months').prop(target_condition, false);
            $('#select_analysis_closing_closure_days').prop(target_condition, true);
        } else if ($('#' + element.id)[0].value == "Inapto temporário") {
            $('#select_analysis_closing_closure_years').prop(target_condition, true);
            $('#select_analysis_closing_closure_months').prop(target_condition, true);
            $('#select_analysis_closing_closure_days').prop(target_condition, false);
        } else {
            $('#select_analysis_closing_closure_years').prop(target_condition, true);
            $('#select_analysis_closing_closure_months').prop(target_condition, true);
            $('#select_analysis_closing_closure_days').prop(target_condition, true);
        }
    }
}
