import { AlertHelper } from '../helpers/AlertHelper';
import { SpinnerHelper } from '../helpers/SpinnerHelper';

export class MedicalRecord {

    constructor(selector) {

        this.alert = new AlertHelper;
        this.spinner = new SpinnerHelper;
        this.select = document.querySelector(selector);
        this.children = document.querySelectorAll('.medical-record-children');
        //this.date_fields = document.querySelectorAll('.updateMedicaRecordDate');

        if (this.children.length > 0)
            this.initChildren();

        // if (this.date_fields.length > 0)
        //   this.initDateFields();
        
        this.init();
        this.test();
        this.definingApproved();
        this.initUpdateDate();
    }

    initUpdateDate() {

        let buttonSave = document.querySelector('button#save-medical-record-date');

        if (buttonSave) {
            buttonSave.addEventListener('click', button => {
               
                let buttonClicket = button.target;

                if (buttonClicket.nodeName == 'I')
                    buttonClicket = buttonClicket.parentNode;
               
                this.saveMedicalRecordDate(buttonClicket.getAttribute('medical-record-id'), buttonClicket   .getAttribute('url'));
            })
        }
    }

    saveMedicalRecordDate(medicalRecordId, url) {
        
        this.spinner.show();
        var date = document.querySelector('#exampleDatepicker13');

        if (!$(date).val()) {
            this.alert.error('Data não definida.');
            this.spinner.hide();
            return;
        }
            
        var params = {
            'medical_record_id': medicalRecordId,
            'date': $(date).val()
        }

        axios.post(url, params)
            .then(response => {

                if (response.data.status == true) {
                    this.alert.success(response.data.message);
                }
            }).catch(error => {
                if (error.response?.data)
                    this.alert.error(error.response.data.message);
            }).then(response => {
                this.spinner.hide();
            })
    }

    definingApproved() {
        let inputs = document.querySelectorAll('[name="radio_analysis_closing_closure"]');

        if (inputs.length > 0) {

            inputs.forEach(input => {

                input.addEventListener('click', (e) => {
                    let wasApproved = document.querySelector('[name="was_approved"]');
                    let description = document.querySelector('[name="approved_description"]');
                    
                    wasApproved.value = (['Inapto temporário', 'Inapto definitivo'].includes(e.target.value)) ? 'not' : 'yes';
                    description.value = e.target.value;
                })
            });
        }
    }

    test() {
        
        $('#collapseOne').on('show.bs.collapse', function (e) {
            
            let input = document.querySelector('#checkbox_accordion_personality_test');

            if (input)
                input.checked = true;
        });

        $('#collapseOne').on('hide.bs.collapse', function (e) {

            let input = document.querySelector('#checkbox_accordion_personality_test');

            if (input)
                input.checked = false;
        });
    }

    initChildren() {

        let self = this;

        this.children.forEach(child => {

            child.addEventListener('change', function () {
                self.showCategory(JSON.parse(child.getAttribute('children')));
            });
        });
    }
    
    showCategory(data, reference) {
      
        let div = document.querySelector('#medical-record-children-select');
        
        div.innerHTML = '';

        if (data.length == 0)
            return;

        let select = document.createElement('select');

        select.classList.add('select');
        select.classList.add('form-control-lg');
        select.classList.add('w-100');
        select.name = 'medical_record_type_children';
        select.setAttribute('refenrece', reference);

        data.forEach(type => {
                    let option = document.createElement('option');
                    option.value = type.id;
                    option.innerText = type.name;

                    if (type.id == div.getAttribute('medical_record_type_id'))
                        option.selected = true;

                    select.appendChild(option);
                });
        
        div.innerHTML = '<label class="form-label select-label mb-0">Categoria</label>';
        div.appendChild(select);
    }



    init() {

        let self = this;

        if (this.select) {
            this.select.addEventListener('change', function () {
                
                Array.prototype.forEach.call(this.options, option => {
                    if (option.hasAttribute('show-children')) {
                        let div = document.querySelector(`.${ option.getAttribute('show-children') }`);
                    
                        self.disabledInputs(div);

                        if (option.selected) {
                            div.style = '';
                            self.anableInputs(div);

                            self.showCategory([]);
                            
                            self.children.forEach(child => {
                                if (child.checked == true && !child.hasAttribute('disabled')) {
                                    self.showCategory(JSON.parse(child.getAttribute('children')), option.getAttribute('show-children'));
                                }
                            });                          
                        } else {
                            div.style = 'display: none';
                        }
                    }
                });
            });
        }
    }



    anableInputs(div) {
        let inputs = div.querySelectorAll('input');

        inputs.forEach(input => {
            input.removeAttribute('disabled');
        });
    }

    disabledInputs(div) {
        let inputs = div.querySelectorAll('input');
        inputs.forEach(input => {
            if (!input.hasAttribute('disabled'))
                input.setAttribute('disabled', '');
        })
    }


    initDateFields() {
      let self = this;
      this.date_fields.forEach(myDatepicker => {
        // field.addEventListener('change', function () {
        //   self.changeMedicalRecordDate(field);
        // });        
        // myDatepicker.addEventListener('dateChange.mdb.datepicker', (e) => {
          // alert('Date has been changed')
        // })
      });
    }
    changeMedicalRecordDate(field){
      let id = field.getAttribute('btn_id');
      let value = field.getAttribute('value');
    }
}
