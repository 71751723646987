import axios from "axios";
import { AlertHelper } from "./AlertHelper";
import { SpinnerHelper } from './SpinnerHelper';

export class CollaboratorHelper {

    constructor() {
        this.activeInputs = document.querySelectorAll('.collaborator-active');
        this.spinner      = new SpinnerHelper;
        this.modal        = document.getElementById('medical-record-modal');
        
        if (this.activeInputs && this.modal) {
            this.myModal      = new mdb.Modal(this.modal);  
            this.init();
        }
    }

    init() {
        this.activeInputs.forEach(input => {
            input.addEventListener('click', event => {
                this.openModal(event);
            })
        })
    }

    addModalButtonListeners(checkboxColaborator) {

        let $btnNot = this.modal.querySelector('#button-not')
        let $btnYes = this.modal.querySelector('#button-yes')

        this.btnToggleClickable($btnYes)

        let confirm = button => {
            this.updateActive(checkboxColaborator, this.model)
            button.target.removeEventListener('click', confirm, false)
            $btnNot.removeEventListener('click', cancel, false)
        }

        let cancel = button => {
            this.myModal.toggle();
            $btnYes.removeEventListener('click', confirm, false)
            button.target.removeEventListener('click', cancel, false)
        }

        $btnNot.addEventListener('click', cancel)
        $btnYes.addEventListener('click', confirm)
    }

    btnToggleClickable($btn, disabled = true) {
        let addClass    = 'btn-danger'
        let removeClass = 'btn-primary'

        $btn.disabled = disabled

        if($btn.disabled) {
            addClass    = 'btn-primary'
            removeClass = 'btn-danger'
        }
        
        $btn.classList.add(addClass)
        $btn.classList.remove(removeClass)
    
    }

    openModal(event)
    {
        event.preventDefault()
        event.stopPropagation();

        let checkboxColaborator = event.target; 
        let alert               = new AlertHelper;
        let data                = JSON.parse(checkboxColaborator.getAttribute('data'));
        
        this.spinner.show();
        this.addModalButtonListeners(checkboxColaborator);

        axios.post(
                checkboxColaborator.getAttribute('medical-record-url'), 
                { doctor_id: data.user_id }
            ).then(response => {
                let $body = this.modal.querySelector('.modal-body');

                $body.innerHTML = '';

                if (
                    response.data.status == true && 
                    response.data.response.length > 0
                ) {
                    response.data.response.forEach(medicalRecord => {
                        $body.innerHTML += this.createMedicalRecordCheckboxItem(medicalRecord);
                    });

                    document.getElementById('alerta-desligamento').style.display = "unset";

                    $body.getElementsByClassName('medical-record-checkbox').forEach(checkbox => {
                        checkbox.addEventListener('click', this.handleMedicalRecordChange.bind(this))
                    })
                } else {
                    let $btnYes = this.modal.querySelector('.modal-footer #button-yes')

                    this.btnToggleClickable(
                        $btnYes,
                        false
                    )

                    let message = 'tornar ativo'

                    if(checkboxColaborator.checked) {
                        message = 'desativar'
                    }

                    $body.innerHTML = `Deseja realmente ${message} o Colaborador?`;
                }

                this.myModal.show();

            }).catch(error => {
                alert.error(error.response.data.message);
            }).then(response => {
                this.spinner.hide();
            });
    }

    createMedicalRecordCheckboxItem(response)
    {
        return `<div style="margin-bottom: 12px;" class="form-check">
                    <input style="top: 21px; margin-right: 18px;" class="form-check-input medical-record-checkbox" type="checkbox" value="${ response.id }" id="med-recrd-${ response.id }" />
                    <label class="form-check-label" for="med-recrd-${ response.id }">
                        <strong>Nome</strong>: ${ response.name } <br />
                        <strong>CPF</strong>: ${ response.cpf } <br />
                        <strong>Protocolo</strong>: ${ response.parent.name } / ${ response.type_name } <br />
                    </label>
                </div`
    }

    updateActive(checkboxColaborator) {

        let alert = new AlertHelper;
        let medicalRecordIds = this.modal.querySelectorAll('input');

        let params = { 
            active: checkboxColaborator.checked, 
            ...JSON.parse(checkboxColaborator.getAttribute('data')),
            medical_record_ids: []
        };

        medicalRecordIds.forEach(medicalRecordId => {
            params['medical_record_ids'].push(medicalRecordId.value);
        })

        axios.post(checkboxColaborator.getAttribute('url'), params)
            .then(response => {
                alert.success(response.data.message);
                
                checkboxColaborator.checked = !checkboxColaborator.checked
            }).catch(error => {
                alert.error(error.response.data.message);
            });
    }

    isAllMedicalRecordsChecked() {
        let isAllMedicalRecordsChecked = true
        let $medicalRecordCheckboxes   = this.modal.querySelector('.modal-body')
            .getElementsByClassName('medical-record-checkbox')


        $medicalRecordCheckboxes.forEach(checkbox => {
            if(!checkbox.checked) {
                isAllMedicalRecordsChecked = false
            }
        })

        return isAllMedicalRecordsChecked
    }

    handleMedicalRecordChange(event) {
        this.btnToggleClickable(
            this.modal.querySelector('.modal-footer #button-yes'),
            !this.isAllMedicalRecordsChecked()
        )
    }
}