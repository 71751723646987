require('./bootstrap');

//import vue
import Vue from 'vue';
import { AddressForm } from './helpers/AddressForm';
import { DualBoxHelper } from './helpers/DualBoxHelper';
import { Mask } from './helpers/Mask';
import { PatientHelper } from './helpers/PatientHelper';
import { PasswordHelper } from './helpers/PasswordHelper';
import { ImageHelper } from './helpers/ImageHelper';
import { FormHelper } from './helpers/FormHelper';
import { MedicalRecord } from './v1/MedicalRecord';
import { AnalysisClosing } from './v1/AnalysisClosing';
import { Interviews } from './v1/Interviews';
import { Tests } from './v1/Tests';
import { Notes } from './v1/Notes';
import { Checkout } from './v1/Checkout';
import * as overlay from 'gasparesganga-jquery-loading-overlay';
import { CalendarHelper } from './helpers/CalendarHelper';
import { CollaboratorHelper } from './helpers/CollaboratorHelper';
import { DatetimeHelper } from './helpers/DatetimeHelper';
import { ModalDialog } from './helpers/ModalDialog';

document.addEventListener('DOMContentLoaded', () => {
    new AnalysisClosing();
    new Interviews();
    new AddressForm('#form-address');
    new Mask('.mask-sgp');
    new Tests();
    new Notes();
    new Checkout();
    new MedicalRecord('#medical-record-childre');
    new DualBoxHelper('.dual-box');
    new ImageHelper;
    new PasswordHelper;
    new FormHelper;
    new PatientHelper;
    new CalendarHelper;
    new CollaboratorHelper;
    new DatetimeHelper;
    new ModalDialog;
});


$(".toggle-password").on('click', function() {
  $(this).toggleClass("fa-eye fa-eye-slash");
  var input = $($(this).attr("toggle"));
  if (input.attr("type") == "password") {
    input.attr("type", "text");
  } else {
    input.attr("type", "password");
  }
});

const initial = $("#nome").text();
$(".pr").prepend(initial.substring(0, 2));


Vue.component('search-patient', require('./components/Search.vue').default);

const app = new Vue({
  el: '#app-vue',
});

 // Example starter JavaScript for disabling form submissions if there are invalid fields
 (() => {
  'use strict';

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms).forEach((form) => {
    form.addEventListener('submit', (event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
    }, false);
  });
})();

$('#password, #password-confirm').on('keyup', function () {
  if ($('#password').val() == $('#password-confirm').val()) {

    $('#validapw').html('A senha confere').css('color', '#00b74a');
  } else
    $('#validapw').html('A senha não confere').css('color', '#ffa900');
});


var inputCpf = $(".login #cpf");
var inputPassword = $(".login #password");
if (inputCpf) {
  inputCpf.addClass("active");
}

if (inputPassword) {
  inputPassword.addClass("active");
}



// js function for tooltip in li element
$(function () {
    var tooltip = $("#tooltip");
    $('[data-tooltip]').bind('mouseover', function () {
        var $this = $(this), offset = $this.offset(), posX = offset.left, posY = offset.top;
        posX += $this.find('span').innerWidth();
        posX += 10;
        tooltip.
            css({ left: posX + "px", top: posY + "px" }).
            text($this.attr('data-tooltip')).
            removeClass("nd");
    }).bind('mouseout', function () { tooltip.addClass('nd'); });
});




//end here


