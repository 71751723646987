import axios from "axios";
import { AlertHelper } from './AlertHelper';
import { SpinnerHelper } from './SpinnerHelper';

export class CalendarHelper {

    constructor() {
        
        this.status = document.querySelector('#calendar-status');
        this.medicalRecordType = document.querySelector('#medical-record-type');
        this.calendarElement = document.getElementById('calendar-zipsi');
        this.spinner = new SpinnerHelper;
        this.events = [];

        if (this.calendarElement) {
            this.calendarInstance = Calendar.getInstance(this.calendarElement);
            this.init();
        }
    }

    init() {

        $(".allD input").prop('checked', false); 
        
        this.getEvents(this.calendarInstance.activeMoment.format('YYYY-MM-DD'));
  
        window.addEventListener('prev.mdb.calendar', (e) => {
            this.getEvents(this.calendarInstance.activeMoment.format('YYYY-MM-DD'));
        });
        window.addEventListener('next.mdb.calendar', (e) => {
            this.getEvents(this.calendarInstance.activeMoment.format('YYYY-MM-DD'));
        });
        window.addEventListener('editEvent.mdb.calendar', (e) => {
            this.updateMediral(e.event)
        });
        this.status.addEventListener('change', () => {
            this.getEvents(this.calendarInstance.activeMoment.format('YYYY-MM-DD'));
        });
        this.medicalRecordType.addEventListener('change', () => {
            this.getEvents(this.calendarInstance.activeMoment.format('YYYY-MM-DD'));
        });
    }

    updateMediral(event) {

        this.spinner.show();

        let alert = new AlertHelper;
        let url = this.calendarElement.getAttribute('update-url');

        let params = {
                    id: event.patient_id,
                    medicalRecordID: event.id,
                    scheduling_date: event.start.dateTime,
                    summary: event.summary,
                    cpf: event.cpf,
                    amount_to_pay: event.amount_to_pay,
                    medical_record_type_id: event.medical_record_type_id,
                    name: event.name,
                    return_json: 1,
                };

        axios.post(url, params)
            .then(response => {

                if (response.data.status == true) {
                    alert.success(response.data.message);
                    
                    this.calendarInstance.refresh();
                } else {
                    alert.error(response.data.message);
                }
            }).catch(error => {console.log(error)
                if (error.response?.data)
                    alert.error(error.response.data.message);
            }).then(response => {
                this.spinner.hide();
            });
    }

    getEvents(date) {

        this.spinner.show();
        
        let alert = new AlertHelper;

        let url = this.calendarElement.getAttribute('list-url');
        let params = { 
                        calendar_date: date,
                        medical_record_type_id: this.medicalRecordType.value,
                        status: this.status.value
                    };

        axios.post(url, params)
            .then(response => {
                
                if (response.data.status == true) {
                    
                    this.calendarInstance.removeEvents();
                    this.events = [];

                    response.data.response.forEach(medicalRecord => {
                        this.events.push(this.setEvent(medicalRecord))
                    });

                    this.calendarInstance.addEvents(this.events);
                } else {
                    alert.error(response.data.message);
                }
            }).catch(error => {
                if (error.response?.data)
                    alert.error(error.response.data.message);
            }).then(response => {
                this.spinner.hide();
            });
    }

    setEvent(event) {

        let type = (event.parent) ? event.parent?.name + ' >' : '';

        return {
            id: event.id,
            patient_id: event.patient_id,
            cpf: event.cpf,
            amount_to_pay: event.amount_to_pay,
            medical_record_type_id: event.type_id,
            name: event.name,
            summary: event.name,
            description: `<strong>Protocolo:</strong> ${ type } ${ event.type_name } <br>
                        <strong>CPF:</strong> ${ event.cpf } <br>
                        <strong>Responsável:</strong> ${ event.doctor_name ?? '' } <br>
                        <strong>E-mail:</strong> ${ event. email } <br>
                        <strong>Fone:</strong> ${ event. phone_number } <br>
                        <strong>UF:</strong> ${ event.uf }<br>
                        <strong>Status:</strong> ${ this.getStatus(event) }
            `,
            start: {
                date: event.scheduling_date,
                dateTime: event.scheduling_date,
            },
            end: {
                date: event.scheduling_date,
                dateTime: event.scheduling_date,
            },
            color: this.getColor(event),
            readonly: (event.status == 'finished') ? true : false,
            addEventButton: false,
        };
    }

    getStatus(event) {

        if (event.status == 'finished') {
            return 'Encerrado';
        } else if (event.status == 'in_progress') {
            return 'Em Andamento';
        } else if (event.status == 'pending') {
            return 'Em Aberto';
        }
    }

    getColor(event) {

        let background = '';
        let foreground = '';

        switch (event.status) {
            case 'finished': 
                background = '#cfe0fc';
                foreground = '#0a47a9';
            break;

            case 'in_progress': 
                background = '#c7f5d9';
                foreground = '#0b4121';
            break;

            case 'pending': 
                background = '#fdd8de';
                foreground = '#790619';
            break;

            default: 
                background = '#c7f5d9';
                foreground = '#0b4121';
        }

        return {
            background,
            foreground,
        }
    }
    
}
