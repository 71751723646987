import axios from "axios";

export class AddressForm {

    constructor(selector) {
        this.form = document.querySelector(selector);

        if (this.form)
            this.init();
    }

    init() {
        let cep = this.form.querySelector('[name="zipcode"]');

        if (cep)
            cep.addEventListener('input', input => {

                if (input.target.value.length == 8) 
                    this.request(input.target.value);
            });
    }

    request(zipcode) {
        axios.post('/zipcode', { zipcode })
            .then(response => {
                
                if (response.data.status == 'success')
                    this.setFormValues(response.data.response)
            }).catch(error => {
                console.log(error.response.data.message)
            });
    }

    setFormValues(response) {

        let address = this.form.querySelector('[name="address"]');
        let district = this.form.querySelector('[name="district"]')
        let city = this.form.querySelector('[name="city_name"]')
        let uf = this.form.querySelector('[name="uf"]')

        if (address)
            address.value = response.logradouro;
        
        if (district)
            district.value = response.bairro;

        if (city)
            city.value = response.localidade;
        
        if (uf)
            uf.value = response.uf;

            address.classList.add("active");
            district.classList.add("active");
            city.classList.add("active");
            uf.classList.add("active");
    }
}

