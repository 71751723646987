import axios from "axios";

export class PatientHelper {

    constructor() {
        this.search = document.querySelector('#cpf-search');
        this.initSearch();
    }

    initSearch() {

        if (!this.search)
            return;

        let self = this;
        
        this.search.addEventListener('input', function () {

            if (self.isValidCPF(this.value)) {
                axios.post(this.getAttribute('url'), { cpf: this.value })
                    .then(response => {
                        let patient = response.data.response.length > 0 ? response.data.response[0] : null;

                        if (patient) {
                            let name = document.querySelector('#nome-patient');
                            let phone = document.querySelector('#phone_number');
                            let email = document.querySelector('#email');
                            let birthDate = document.querySelector('#b_date');
                            let zipcode = document.querySelector('#cep');
                            let withSpecialNeeds = document.querySelector('#with_special_needs');
                            let educationLevelId = document.querySelector('[name="education_level_id"]');
                            let useHands = document.querySelectorAll('[name="use_hand"]');
                            let hasSupletivo = document.querySelectorAll('[name="has_supletivo"]');
                            let address = this.form.querySelector('[name="address"]');
                            let additional = this.form.querySelector('[name="additional"]');
                            let district = this.form.querySelector('[name="district"]');
                            let number = this.form.querySelector('[name="number"]');
                            let city = this.form.querySelector('[name="city_name"]');
                            let uf = this.form.querySelector('[name="uf"]');
                            let exists = this.form.querySelector('[name="exists_id"]');
   
                            exists.value = patient.id;
                            name.value = patient.name;
                            phone.value = patient.phone_number;
                            email.value = patient.email;

                            name.classList.add("active");
                            phone.classList.add("active");
                            email.classList.add("active");
                            
                            if (birthDate) {
                                birthDate.value = self.formatDate(patient.birth_date);
                                withSpecialNeeds.value = patient.with_special_needs;
                                educationLevelId.value = patient.education_level_id;
                                
                                hasSupletivo.forEach(hasSuple => {
                                    if (hasSuple.value == patient.has_supletivo)
                                        hasSuple.checked = true;
                                    else
                                        hasSuple.checked = false;
                                });

                                useHands.forEach(useHand => {
                                    if (useHand.value == patient.use_hand)
                                        useHand.checked = true;
                                    else
                                        useHand.checked = false;
                                })

                                birthDate.classList.add("active");
                                withSpecialNeeds.classList.add("active");
                                educationLevelId.classList.add("active");
                            }

                            if (zipcode) {
                                zipcode.value = patient.zipcode;
                                address.value = patient.address;
                                additional.value = patient.additional;
                                district.value = patient.district;
                                city.value = patient.city_name;
                                number.value = patient.number;
                                uf.value = patient.uf;

                                zipcode.classList.add("active");
                                address.classList.add("active");
                                additional.classList.add("active");
                                district.classList.add("active");
                                city.classList.add("active");
                                uf.classList.add("active");
                                number.classList.add("active");
                            }

                        }
                    }).catch(error => {

                    }).then(response => {

                    });
            }
        })
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [day, month, year].join('/');
    }

    isValidCPF(cpf) {

        if (typeof cpf !== "string") return false
        cpf = cpf.replace(/[\s.-]*/igm, '')
        if (
            !cpf ||
            cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999" 
        ) {
            return false
        }
        var soma = 0
        var resto
        for (var i = 1; i <= 9; i++) 
            soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if ((resto == 10) || (resto == 11))  resto = 0
        if (resto != parseInt(cpf.substring(9, 10)) ) return false
        soma = 0
        for (var i = 1; i <= 10; i++) 
            soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
        resto = (soma * 10) % 11
        if ((resto == 10) || (resto == 11))  resto = 0
        if (resto != parseInt(cpf.substring(10, 11) ) ) return false
        return true
    }
}


