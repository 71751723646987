<template>
    <div id="header-search"  @mouseleave="handleFocusout">
        <form class="w-auto d-none d-sm-flex ms-5 bsc">
            <input
                id="search_box"
                v-model="search_box"
                autocomplete="off"
                type="text"
                class="form-control"
                placeholder="Buscar por CPF"
                @input="search"
                ref="search_term"
                pattern="[0-9\.\\-]+"
                />

            <span class="input-group-text border-0 d-none d-lg-flex">
                <div class="spinner-border spinner-border-sm"
                    role="status"
                    v-if="loading">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <i v-if="!loading" class="fas fa-search"></i>
            </span>
        </form>

        <div id="search-ul" style="width: 800px;" ref="list" v-if="searchShow">
            últimos 10 atendimentos
            <ul class="list-group">
                <template v-for="(item, i) in data">

                    <li class="list-group-item list-group-action d-flex justify-content-between align-items-center" style='cursor:pointer'
                        :key="i"
                        @click="redirect(item)">

                        <div class="row">
                            <div class="row">
                                <div class="fw-bold col-3" v-text="item.name"></div>
                                <div class="text-muted col-3"><strong>CPF:</strong> {{item.cpf}}</div>
                                <div class="text-muted col-6" v-if="item.parent">{{ item.parent.name }} / {{ item.type_name }}</div>
                                <div class="text-muted col-3" v-else>{{ item.type_name }}</div>
                            </div>
                            <div class="row">
                                <div class="text-muted col-3"><strong> Status:</strong>
                                    <span class="badge rounded-pill badge-success" v-if="item.status == 'in_progress'">Em atendimento</span>
                                    <span class="badge rounded-pill badge-success" v-if="item.status == 'pending'">Não atendido</span>
                                    <span class="badge rounded-pill badge-success" v-if="item.status == 'finished'">Finalizado</span>
                                </div>
                                <div class="text-muted col-3" v-if="item.doctor_name != ''"><strong>Responsável: </strong>{{ item.doctor_name }}</div>
                                <div class="text-muted col-2" v-if="item.crp != ''"><strong> CRP:</strong> {{ item.crp }}</div>
                                <div class="text-muted col-4" v-if="item.attended_at != ''"><strong> Atendimento:</strong> {{ formatDate(item.attended_at)  }}</div>
                            </div>
                            <div class="row">
                                <div class="text-muted col-5" v-if="item.status=='finished'"><strong> Aprovado:</strong>
                                    <span class="badge rounded-pill badge-success" v-if="item.was_approved == 'yes'">Sim</span>
                                    <span class="badge rounded-pill badge-danger" v-if="item.was_approved == 'not'">Não</span>
                                    <span v-if="item.approved_description">
                                        <span class="badge rounded-pill badge-success" v-if="item.approved_description.startsWith('Apto')">{{ item.approved_description }}</span>
                                        <span class="badge rounded-pill badge-danger" v-else>{{ item.approved_description }}</span>
                                    </span>

                                </div>
                             </div>
                        </div>
                    </li>
                </template>
                <div class="row" v-if="notFound">
                    <div class="text-muted col-4"><strong>CPF não encontrado</strong></div>
                </div>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

    props: ['institution-id', 'url'],

    data() {
        return {
            data: [],
            loading: false,
            myTimeout: null,
            searchShow: false,
            notFound: false,
            search_box: ''
        }
    },

    methods: {

        handleFocusout(el) {
            this.data = [];
            this.searchShow = false;

        },

        handleFocus() {
            // do something here
        },

        redirect(item) {
            window.location.href = `/medical-records/${ item.id }/edit`;
        },

        search(el) {

            if (el.target.value.length <= 2) {
                this.data = [];
                this.searchShow = false;
                return;
            }

            clearTimeout(this.myTimeout);

            this.loading = true;

            this.myTimeout = setTimeout(() => { this.request(el); }, 2000);
        },

        getParams(value) {

            let isCpf = this.isValidCPF(value);
            let isString = new String(value);

            if (isCpf)
                return { cpf: value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4') };
            else if (typeof value === 'string' || value instanceof String)
                return { name: value };

            return false;

        },

        formatDate(dateString) {
            if(!dateString)
                return('Sem atendimento');
            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted

            return new Intl.DateTimeFormat('pt-BR').format(date);
        },

        request(el) {
            let params = {
                cpf: el.target.value
            };
            
            if (!params) {
                this.loading = false;
                return;
            }

            axios.post(this.url, params)
                .then(response => {
                    this.data = response.data.response;
                    if(this.data.length > 0) {
                        this.searchShow = true;
                        this.notFound = false;
                    } else {
                        this.searchShow = true;
                        this.notFound = true;
                    }

                }).catch(error => {

                }).then(response => {
                    this.loading = false;

                });
        },

        isValidCPF(cpf) {
            if (typeof cpf !== "string") return false
            cpf = cpf.replace(/[\s.-]*/igm, '')
            if (
                !cpf ||
                cpf.length != 11 ||
                cpf == "00000000000" ||
                cpf == "11111111111" ||
                cpf == "22222222222" ||
                cpf == "33333333333" ||
                cpf == "44444444444" ||
                cpf == "55555555555" ||
                cpf == "66666666666" ||
                cpf == "77777777777" ||
                cpf == "88888888888" ||
                cpf == "99999999999"
            ) {
                return false
            }
            var soma = 0
            var resto
            for (var i = 1; i <= 9; i++)
                soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
            resto = (soma * 10) % 11
            if ((resto == 10) || (resto == 11))  resto = 0
            if (resto != parseInt(cpf.substring(9, 10)) ) return false
            soma = 0
            for (var i = 1; i <= 10; i++)
                soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
            resto = (soma * 10) % 11
            if ((resto == 10) || (resto == 11))  resto = 0
            if (resto != parseInt(cpf.substring(10, 11) ) ) return false
            return true
        }
    }
}
</script>