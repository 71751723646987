import axios from 'axios';

require('datatables.net-responsive');
require( 'datatables.net-rowreorder' )

export class FormHelper {

    constructor() {
        
        this.initDatatable();
        this.initStep()
        this.initShowInTheList();
    }

    initShowInTheList() {
        let inputs = document.querySelectorAll('.show-in-the-list');

        if (inputs.length == 0)
            return;

        inputs.forEach(input => {
            input.addEventListener('click', input => {
                console.log(input.target)
                this.saveShowInTheList(input.target)
           });
        });
    }

    initStep() {

        let selects = document.querySelectorAll('select.medical-form-steps');

        if (selects.length == 0)
            return;

        selects.forEach(select => {

           select.addEventListener('change', select => {
                this.saveStep(select.target)
           });
        });
    }

    saveStep(select) {
        
        let form = new FormData;
        form.append('form_id', select.getAttribute('form-id'));
        form.append('medical_record_type_id', select.getAttribute('medical-record-type-id'));
        form.append('step', parseInt(select.value));

        axios.post(select.getAttribute('url'), form)
            .then(response => {
                console.log(response)
            }).catch(error => {

            });
    }

    saveShowInTheList(input) {

        let form = new FormData;
        form.append('form_id', input.getAttribute('form-id'));
        form.append('medical_record_type_id', input.getAttribute('medical-record-type-id'));
        form.append('show_in_the_list', input.checked ? 1 : 0);

        axios.post(input.getAttribute('url'), form)
            .then(response => {
                console.log(response)
            }).catch(error => {

            });
    }

    initDatatable () {
        let self = this;
        let table = document.querySelector('#dtBasicExample');

        if (!table) 
            return;

        let datatable = $(table).DataTable( {
                            paging: false,
                            searching: false,
                            rowReorder: true,
                        });

        datatable.on( 'row-reorder', function ( e, diff, edit ) {
            
            if (diff.length == 0)
                return;

            let params = { 
                            new_position: [], 
                            old_position: [], 
                            form_ids: [],
                            steps: [],
                            medical_record_type_id: parseInt(table.getAttribute('medical-record-type-id')),
                        };

            for ( var i=0, ien=diff.length ; i<ien ; i++ ) {
                let rowData = datatable.row( diff[i].node ).data();

                params.new_position.push(diff[i].newPosition);
                params.old_position.push(diff[i].oldPosition);
                params.form_ids.push(parseInt(rowData[1]));
            }

            axios.post(table.getAttribute('order-url'), params)
                .then(response => {
                    
                    if ( response.data.status == true )
                        $.notify(response.data.response, "success");
                    else
                        $.notify(response.data.response);
                }).catch(error => {
                    $.notify(response.data.response);
                });
        });
    }
}