import { DualListbox } from 'dual-listbox';

export class DualBoxHelper {

    constructor(selector, options = []) {

        this.hasSelects = document.querySelectorAll(selector);

        if (this.hasSelects.length > 0)
            this.init(options);
    }

    init(options) {
        
        this.hasSelects.forEach(select => {
           
            let dualListbox = new DualListbox('[name="' + select.name + '"]', {
                                availableTitle: 'Não selecionados',
                                selectedTitle: 'Selecionados',
                                addButtonText: '>',
                                removeButtonText: '<',
                                addAllButtonText: '>>',
                                removeAllButtonText: '<<',
                                moveOnSelect: false,
                                preserveSelectionOnMove: true,
                                options,
                            });

        });
    }
}