export class Checkout {
	constructor() {
		this.profile = null;
		this.checkout_button = document.getElementById('checkout_button');
		this.init();
	}

	init() {

		let _self = this;

		if (this.checkout_button) {
			this.checkout_button.addEventListener('click', function () {
				const profile = _self.loadProfile();

				if (profile) {
					$.LoadingOverlay("show");
					this.profile = profile;
					_self.createPaymentProfile(this.profile);
				}
			});
		}
	}

	createPaymentProfile(profile){

		var _self = this;

		var vindi_api_url = process.env.MIX_VINDI_API_URL;
		var vindi_public_api_key = process.env.MIX_VINDI_PUBLIC_API_KEY;

		console.log(process.env.MIX_VINDI_API_URL);

		$.ajax({
			type: "POST",
			url: vindi_api_url + "public/payment_profiles",
			dataType: "json",
			headers: { "Authorization": "Basic " + btoa(vindi_public_api_key + ":") },
			data: profile,
			success: function(response){
				var gateway_token = response.payment_profile.gateway_token;
				_self.makePayment(gateway_token, profile)
			},
			error: function(request, status, error) {
				$.LoadingOverlay("hide");
				console.log(request.responseText);
				var responseText = JSON.parse(request.responseText);
				var errors = responseText.errors;
				var msg = "";

				errors.forEach(function(er, i) {
				if(er.parameter != 'payment_profile'){
					var field = _self.getFieldName(er.parameter);
					msg += field + ": " + er.message + "\n";
				}
				})
				console.log(msg);
				alert("Erro:\n" + msg);
			}
		});
	}

	getFieldName(parameter){
		const fields = [
		{ name: 'holder_name', title: 'Nome do Titular'},
		{ name: 'card_expiration', title: 'Validade'},
		{ name: 'card_number', title: 'Número do cartão'},
		{ name: 'card_cvv', title: 'Código de segurança'}
		];

		const field = fields.find( field => field.name === parameter);
		return (field ? field.title : parameter);
	}

	makePayment(gateway_token, profile){

		var card_number_mask = this.maskCardNumber(profile.card_number);
		var data = {
		"_token": document.getElementsByName('_token')[0].value,
		"plan_id": document.getElementById('plan_id').value,
		"gateway_token": gateway_token,
		"card_number_mask": card_number_mask,
		"card_name": profile.holder_name,
		"expiration_month": document.getElementById('expiration_month').value,
		"expiration_year": document.getElementById('expiration_year').value,
		"cvc": profile.card_cvv
		}
	
		var form = $("#payment_form");
		var actionUrl = form.attr('action');

		var data_form = form.serializeArray();
		for(var i = 0, len = data_form.length; i < len; ++i) {
		var item = data_form[i];
		data[item.name] = item.value;
		}

		$.ajax({
		type: "POST",
		url: actionUrl,
		data: data,
		success: function(response){
			$.LoadingOverlay("hide");
			window.location.href = response.url;
		},
		error: function(request, status, error) {
			$.LoadingOverlay("hide");
			alert("erro");
			console.log('erro');
			console.log(request);
			console.log(status);
			console.log(error);
		}
		});
	}

	loadProfile(){

		var holder_name = document.getElementById('card_name').value;
		var card_number = document.getElementById('card_number').value;
		var card_cvv = document.getElementById('cvc').value;

		var expiration_month = document.getElementById('expiration_month').value;
		var expiration_year = document.getElementById('expiration_year').value;
		var card_expiration = expiration_month + "/" + expiration_year;

		var profile = {
						"holder_name": holder_name,
						"card_expiration": card_expiration,
						"card_number": card_number,
						"card_cvv": card_cvv,
						"payment_method_code": "credit_card"
					};

		var errors = [];

		if (card_number.length < 1)
			errors.push('Preencha o campo Número do cartão.');

		if ((expiration_month.length < 1) || (expiration_year.length < 1))
			errors.push('Preencha o campo Validade.');

		if (holder_name.length < 1)
			errors.push('Preencha o campo Nome do Titular.');

		if (card_cvv.length < 1)
			errors.push('Preencha o campo Código de Segurança.');

		if (errors.length > 0) {
			this.printErrorMsg(errors);
			return false;
		} 

		this.clearErrors();

		return profile;
	}

	printErrorMsg(errors) {
		$(".print-error-msg").find("ul").html('');
		$(".print-error-msg").css('display','block');
		errors.forEach(function(value, i) {
		$(".print-error-msg").find("ul").append('<li>'+value+'</li>');
		});
	}

	clearErrors(){
		$(".print-error-msg").find("ul").html('');
		$(".print-error-msg").css('display','none');
	}

	maskCardNumber(cardNumber) {
		return `${'*'.repeat(cardNumber.length - 4)}${cardNumber.substr(cardNumber.length - 4)}`;
	}
}