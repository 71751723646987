export class AlertHelper {

    constructor() {

        this.type = null;
        this.types = [
                        //'primary',
                        //'secondary',
                        'success',
                        'danger',
                        //'warning',
                        //'info',
                        //'light',
                        'dark',
                    ];
        this.alert = document.getElementById('alert-default');

        this.types.forEach(type => {
            if (this.alert.classList.contains('alert-' + type))
                this.alert.classList.remove('alert-' + type);
        })
    }

    success(message) {

        this.message = message;
        this.type = 'success';
        this.alert.setAttribute('data-mdb-color', "success");
        this.alert.classList.add('alert-success');
        mdb.Alert.getInstance(this.alert).show();
    }

    error(message) {

        this.message = message;
        this.type = 'danger';
        this.alert.setAttribute('data-mdb-color', "danger");
        this.alert.classList.add('alert-danger');
        mdb.Alert.getInstance(this.alert).show();
    }

    set message(message) {
        this.alert.innerHTML = message;
    }
}