export class Tests {
    constructor() {
        this.checkbox_testing_cognitive_testing_attention_not_applied_1 = document.getElementById('checkbox_testing_cognitive_testing_attention_not_applied_1');
        this.select_testing_cognitive_testing_attention_type_1 = document.getElementById('select_testing_cognitive_testing_attention_type_1');
        this.select_testing_cognitive_testing_attention_percentile_1 = document.getElementById('select_testing_cognitive_testing_attention_percentile_1');
        this.select_testing_cognitive_testing_attention_classification_1 = document.getElementById('select_testing_cognitive_testing_attention_classification_1');

        this.checkbox_testing_cognitive_testing_attention_not_applied_2 = document.getElementById('checkbox_testing_cognitive_testing_attention_not_applied_2');
        this.select_testing_cognitive_testing_attention_type_2 = document.getElementById('select_testing_cognitive_testing_attention_type_2');
        this.select_testing_cognitive_testing_attention_percentile_2 = document.getElementById('select_testing_cognitive_testing_attention_percentile_2');
        this.select_testing_cognitive_testing_attention_classification_2 = document.getElementById('select_testing_cognitive_testing_attention_classification_2');

        this.checkbox_testing_cognitive_testing_attention_not_applied_3 = document.getElementById('checkbox_testing_cognitive_testing_attention_not_applied_3');
        this.select_testing_cognitive_testing_attention_type_3 = document.getElementById('select_testing_cognitive_testing_attention_type_3');
        this.select_testing_cognitive_testing_attention_percentile_3 = document.getElementById('select_testing_cognitive_testing_attention_percentile_3');
        this.select_testing_cognitive_testing_attention_classification_3 = document.getElementById('select_testing_cognitive_testing_attention_classification_3');

        this.checkbox_testing_cognitive_testing_reasoning_not_applied = document.getElementById('checkbox_testing_cognitive_testing_reasoning_not_applied');
        this.select_testing_cognitive_testing_reasoning_percentile = document.getElementById('select_testing_cognitive_testing_reasoning_percentile');
        this.select_testing_cognitive_testing_reasoning_classification = document.getElementById('select_testing_cognitive_testing_reasoning_classification');
/* possivelmente duplicado
        this.checkbox_testing_cognitive_testing_reasoning_not_applied = document.getElementById('checkbox_testing_cognitive_testing_reasoning_not_applied');
        this.select_testing_cognitive_testing_reasoning_percentile = document.getElementById('select_testing_cognitive_testing_reasoning_percentile');
        this.select_testing_cognitive_testing_reasoning_classification = document.getElementById('select_testing_cognitive_testing_reasoning_classification');
*/
        this.checkbox_testing_cognitive_testing_memory_not_applied = document.getElementById('checkbox_testing_cognitive_testing_memory_not_applied');
        this.select_testing_cognitive_testing_memory_percentile = document.getElementById('select_testing_cognitive_testing_memory_percentile');
        this.select_testing_cognitive_testing_memory_classification = document.getElementById('select_testing_cognitive_testing_memory_classification');

        this.init();
    }

    init() {
        let _self = this;

        let url_atual = window.location.href;

        if (url_atual.search('tests') != -1) {
            this.checkbox_testing_cognitive_testing_attention_not_applied_1.addEventListener('change', function () {
                _self.fieldEnablementController(
                    'checkbox_testing_cognitive_testing_attention_not_applied_1',
                    'checked',
                    [
                        'select_testing_cognitive_testing_attention_type_1',
                        'select_testing_cognitive_testing_attention_percentile_1',
                        'select_testing_cognitive_testing_attention_classification_1'
                    ],
                    'disabled');
            });

            this.checkbox_testing_cognitive_testing_attention_not_applied_2.addEventListener('change', function () {
                _self.fieldEnablementController(
                    'checkbox_testing_cognitive_testing_attention_not_applied_2',
                    'checked',
                    [
                        'select_testing_cognitive_testing_attention_type_2',
                        'select_testing_cognitive_testing_attention_percentile_2',
                        'select_testing_cognitive_testing_attention_classification_2'
                    ],
                    'disabled');
            });

            this.checkbox_testing_cognitive_testing_attention_not_applied_3.addEventListener('change', function () {
                _self.fieldEnablementController(
                    'checkbox_testing_cognitive_testing_attention_not_applied_3',
                    'checked',
                    [
                        'select_testing_cognitive_testing_attention_type_3',
                        'select_testing_cognitive_testing_attention_percentile_3',
                        'select_testing_cognitive_testing_attention_classification_3'
                    ],
                    'disabled');
            });

            this.checkbox_testing_cognitive_testing_reasoning_not_applied.addEventListener('change', function () {
                _self.fieldEnablementController(
                    'checkbox_testing_cognitive_testing_reasoning_not_applied',
                    'checked',
                    [
                        'select_testing_cognitive_testing_reasoning_percentile',
                        'select_testing_cognitive_testing_reasoning_classification'
                    ],
                    'disabled');
            });

            this.checkbox_testing_cognitive_testing_memory_not_applied.addEventListener('change', function () {
                _self.fieldEnablementController(
                    'checkbox_testing_cognitive_testing_memory_not_applied',
                    'checked',
                    [
                        'select_testing_cognitive_testing_memory_percentile',
                        'select_testing_cognitive_testing_memory_classification'
                    ],
                    'disabled');
            });
        }
    }

    /*
        @description: Função responsável por controlar (por id e checkbox) o estado dos inputs (ex.: habilitar/desabilitar)
        @param controller: Controlador
        @param controller_condition: Condição do controlador
        @param targets: Objetos alvo
        @param target_condition: Condição do objeto alvo
    */
    fieldEnablementController(controller, controller_condition, targets, target_condition) {
        Array.prototype.forEach.call(targets, function(_target) {
            if($('#' + controller).is(':' + controller_condition)) {
                $('#' + _target).prop(target_condition, true);
            } else {
                $('#' + _target).prop(target_condition, false);
            }
        });
    }
}
